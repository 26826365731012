import axiosServices from "../utils/axios";

const CourseService = {
  updateStatus: async (userId, status, courseId, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId + "/enable-course",
        { 
            id:courseId,
            status: status
         },
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
};
export default CourseService;
