// Pagination.jsx
import React from 'react';

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNeighbours = 1; // Number of page numbers to show around the current page

    // Function to generate page number range
    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    };

    // Function to determine the page numbers to display
    const fetchPageNumbers = () => {
        const totalNumbers = pageNeighbours * 2 + 3; // Start, End, Current, Neighbours
        const totalBlocks = totalNumbers + 2; // Include next and previous

        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            let pages = range(startPage, endPage);
            
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (pages.length + 1);
            
            if (hasLeftSpill && !hasRightSpill) {
                const extraPages = range(startPage - spillOffset, startPage - 1);
                pages = ['...', ...extraPages, ...pages];
            } else if (!hasLeftSpill && hasRightSpill) {
                const extraPages = range(endPage + 1, endPage + spillOffset);
                pages = [...pages, ...extraPages, '...'];
            } else if (hasLeftSpill && hasRightSpill) {
                pages = ['...', ...pages, '...'];
            }
            return [1, ...pages, totalPages];
        }

        return range(1, totalPages);
    };

    return (
        <nav>
            <ul className='flex justify-center space-x-2 mt-4'>
                <li>
                    <button 
                        onClick={() => currentPage > 1 && paginate(currentPage - 1)} 
                        className='px-4 py-2 border rounded cursor-pointer hover:bg-gray-200'
                    >
                        Previous
                    </button>
                </li>
                {fetchPageNumbers().map(number => (
                    <li className='list-none'>
                        {number === '...' ? (
                            <span className='px-4 py-2 border rounded'>...</span>
                        ) : (
                            <button 
                                onClick={() => paginate(number)} 
    
                                className={`px-4 py-2 border rounded ${currentPage === number ? 'bg-gray-200' : ''} cursor-pointer`}
                            >
                                {number}
                            </button>
                        )}
                    </li>
                ))}
                <li>
                    <button 
                        onClick={() => currentPage < totalPages && paginate(currentPage + 1)} 
                        className='px-4 py-2 border rounded cursor-pointer hover:bg-gray-200'
                    >
                        Next
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;
