import { createContext, useState, useEffect } from "react";
import { useCookies } from 'react-cookie';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [cookies, setCookie] = useCookies(['auth']);
    const [auth, setAuth] = useState(cookies.auth || {});
    useEffect(() => {
        // Set the 'auth' cookie whenever 'auth' state changes
        setCookie('auth', auth, { path: '/' });
    }, [auth, setCookie]);
    return (
        <AuthContext.Provider value={{ auth, setAuth }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;