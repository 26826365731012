import React from "react";

function PopupModal({ isOpen, onClose, onConfirm, message }) {
  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative p-4 w-full max-w-md">
            <div className="relative bg-white rounded-lg shadow">
              <div className="p-4 text-center">
                <p className="text-gray-700">{message}</p>
              </div>
              <div className="flex justify-end p-4 space-x-4">
                <button
                  onClick={onClose}
                  className="py-2 px-4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100"
                >
                  Cancel
                </button>
                <button
                  onClick={onConfirm}
                  className="py-2 px-4 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:ring-4 focus:ring-red-300"
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopupModal;