import React, { useContext } from 'react'
import AuthContext from '../contexts/AuthContext'

export default function Dashboard() {
  const { auth } = useContext(AuthContext);
  if(auth?.adminEmail){
    return (
      <div>You Logged in as {auth?.adminEmail}</div>
    )
  } else {
    return(
      <div>You are a guest!</div>
    )
  }
}
