// UserTable.jsx
import React from "react";
import { Link } from "react-router-dom";

const UserTable = ({ users }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full leading-normal">
        <thead>
          <tr>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Name
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Email
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Actions
            </th>
            <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
              Status
            </th>
            {/* Add more headers if needed */}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td className="px-5 py-4 border-b border-gray-200 bg-white text-sm">
                {user.name}
              </td>
              <td className="px-5 py-4 border-b border-gray-200 bg-white text-sm">
                {user.email}
              </td>
              <td className="px-5 py-4 border-b border-gray-200 bg-white text-sm text-center">
                <Link to={`/user/${user.id}`}>
                  <button
                    type="button"
                    className="text-white bg-dark focus:outline-none focus:ring-4 font-medium rounded-full text-sm px-5 py-2.5 text-center"
                  >
                    Manage
                  </button>
                </Link>
              </td>
              <td className="px-5 py-4 border-b border-gray-200 bg-white text-sm text-center">
                <span
                  className={user.status ? "text-green-500" : "text-red-500"}
                >
                  {user.status ? "Active" : "Inactive"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
