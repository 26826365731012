import LogoutModel from "../components/LogoutModel";

export const SIDEBAR_LINKS = [
    {
        key: 'dashboard',
        label: 'Dashboard',
        path: '/',
        icon: ''
    },
    {
        key: 'users',
        label: 'Users',
        path: '/users',
        icon: ''
    },
]

export const SIDEBAR_BOTTOM_LINKS = [
    {
        key: 'settings',
        label: 'Settings',
        path: '/settings',
        icon: ''
    },
    {
        key: "logout",
        label: <LogoutModel />,
        icon: "",
        component: LogoutModel,
    },
]