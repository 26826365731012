import axiosServices from '../utils/axios';


const AuthService =  {
    login: async (email, password) => {
        try {
            const response = await axiosServices.post('/admin/login', {email, password});
            return response;
            
        } catch (error) {
            return { status: 401}
        }
    },
    logout: async (token) => {
        try {
          const response = await axiosServices.post("/admin/logout", null, {
            headers: {
              "X-AUTH-TOKEN": token,
            },
          });
          return response;
        } catch (error) {
          return { status: error?.response?.status };
        }
      },
}

export default AuthService;