import React from "react";
import { SIDEBAR_LINKS, SIDEBAR_BOTTOM_LINKS } from "../../routes/navigation";
import { Link, useLocation } from "react-router-dom";

const linkClasses = 'flex item-center gap-2 font-light mr-1 ml-1 mt-1 px-3 py-2 hover:bg-dark hover:text-white hover:no-underline rounded-sm text-base';

export default function Sidebar() {
  return (
    <div className="bg-white w-60 flex flex-col text-black">
    <img className="px-6 py-6" src="logo.png" alt="Brainlab" />
      <div className="flex-1">
        {SIDEBAR_LINKS.map((item) => (
          <SidebarLink key={item.key} item={item} />
        ))}
      </div>
      <div className="flex flex-col gap-0.5 pt-2 border-t border-neutral-700">
      {SIDEBAR_BOTTOM_LINKS.map((item) => (
          <SidebarLink key={item.key} item={item} />
        ))}
      </div>
    </div>
  );
}

function SidebarLink({ item }) {
  const { pathname } = useLocation();
  const newclasses = (pathname === item.path ? 'bg-dark text-white' : 'text-black') + ' ' + linkClasses;
  return (
    <Link to={item.path} className={newclasses}>
      <span className="text-xl">{item.icon}</span>
      {item.label}
    </Link>
  )
  
}