import React from 'react';
import { Route, Routes } from 'react-router-dom'
// routes
import Login from '../pages/auth/Login';
import Layout from '../components/Layout';
import Dashboard from '../pages/Dashboard';
import Users from '../pages/Users';
import Settings from '../pages/Settings';
import ManageUser from '../pages/ManageUser';
import RequireAuth from '../components/RequireAuth';


//-----------------------|| ROUTING RENDER ||-----------------------//

const RouteList = () => {
    return (
        <Routes>
            <Route path="login" element={<Login />} /> 

            <Route element={<RequireAuth />}>
            <Route path="/" element={<Layout />}>
                <Route index element={<Dashboard />} />
                <Route path="users" element={ <Users />} />
                <Route path="settings" element={<Settings />} />
                <Route path="user/:userId" element={<ManageUser />} />
            </Route>
            </Route>
            
      </Routes>
    );
};

export default RouteList;
