import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import AuthContext from '../contexts/AuthContext'

export default function RequireAuth() {

    const { auth } = useContext(AuthContext);
    const location = useLocation();

  return (
    (auth?.adminEmail && auth?.adminToken)
        ? <Outlet />
        : <Navigate to="/login" state={{ from: location }} replace />
  )
}
