import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar";
import ToastContainer from "../ToastContainer";
export default function Layout() {
  return (
    <div className="flex flex-row bg-neutral-100 h-screen w-screen overflow-hidden bg-mid">
      <ToastContainer />
    <Sidebar />
      <div className="p-4">
        <div>{<Outlet />}</div>
      </div>
    </div>
  );
}
