import React from "react";
import style from './ToggleButton.module.css';

const ToggleButton = ({ isChecked, onToggle }) => {
  return (
    <div className="inline-block items-center px-5">
      <label htmlFor="toggle" className="flex items-center cursor-pointer">
        <div className="relative">
          <input
            id="toggle"
            type="checkbox"
            className="sr-only"
            defaultChecked={isChecked}
            onChange={onToggle}
          />
          <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"></div>
          <div className={`absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition ${isChecked ? style.dot : ''}`}></div>
        </div>
      </label>
    </div>
  );
};

export default ToggleButton;
