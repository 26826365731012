import React from 'react';

export default function Settings() {


  return (
    <div>Settings page comming soon
    </div>
  )
}
