import React, { useState } from 'react';

const SearchBox = ({ onSearch }) => {
    const [searchQuery, setSearchQuery] = useState('');

    const handleInputChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleSearchClick = () => {
        onSearch(searchQuery);
    };

    return (
        <div className="flex justify-center mb-4">
            <input
                type="text"
                className="form-input px-4 py-2 border rounded mr-2"
                placeholder="Search by email..."
                value={searchQuery}
                onChange={handleInputChange}
            />
            <button
                className="bg-dark border border-dark text-white font-bold py-2 px-4 rounded"
                onClick={handleSearchClick}
            >
                Search
            </button>
        </div>
    );
};

export default SearchBox;
