import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { useParams } from "react-router-dom";
import UserService from "../services/UserService";
import { useToast } from "../contexts/ToastContext";
import PopupModal from "../components/PopupModal";
import ToggleButton from "../components/ToggleButton";
import CourseService from "../services/CourseService";

export default function ManageUser() {
  const { auth } = useContext(AuthContext);
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [newPassword, setNewPassword] = useState("");
  const { addToast } = useToast();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(false);

  const [isResetConfirmationOpen, setResetConfirmationOpen] = useState(false);

  const openResetConfirmation = () => {
    setResetConfirmationOpen(true);
  };

  const closeResetConfirmation = () => {
    setResetConfirmationOpen(false);
  };

  const updateCourseStatus = async (newStatus,courseId) => {
    // return;
    try {
      const response = await  CourseService.updateStatus(
        userId,
        newStatus,
        courseId,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        // Set users data from the response
        toggleCourseStatus(newStatus,courseId);
        addToast("success", "Course Status Updated successfully!");
      } else {
        // Handle error response
        addToast("error", "Unable to update course status. Please try again.");
        console.error("Failed to update course status:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to update course status. Please try again.");
      console.error("Error updating course status:", error.message);
    }
  };
  const toggleCourseStatus = (newStatus, courseId) => {
    setUser((prevUser) => ({
      ...prevUser,
      courses: prevUser.courses.map((course) => (course.userCourse === courseId ? { ...course,status:newStatus} : course)),
    }));
  };
  
  const toggleUserStatus = async () => {
    try {
      const response = await UserService.updateUserStatus(
        userId,
        !status,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        setStatus(!status);
        // Set users data from the response
        addToast("success", "User Status Updated successfully!");
      } else {
        // Handle error response
        addToast("error", "Unable to update user status. Please try again.");
        console.error("Failed to update user status:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to update user status. Please try again.");
      console.error("Error updating user status:", error.message);
    }
  };

  const killTokens = async () => {
    try {
      const response = await UserService.killUserTokens(
        userId,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        // Set users data from the response
        addToast("success", "User sessions cleared successfully!");
      } else {
        // Handle error response
        addToast("error", "Unable to kill user sessions. Please try again.");
        console.error("Failed to kill user sessions:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to kill user sessions. Please try again.");
      console.error("Error clearing user sessions:", error.message);
    }
  };

  const resetUserTasks = async (courseId) => {
    try {
      if (!courseId) {
        throw new Error("courseId error");
      }
      const response = await UserService.resetUserTasks(
        userId,
        courseId,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        // Set users data from the response
        addToast("success", "User task data resetted successfully!");
      } else {
        // Handle error response
        addToast("error", "Failed to reset user task data. Please try again.");
        console.error("Failed to update task status:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to reset user task data. Please try again.");
      console.error("Error updating task status:", error.message);
    }

    closeResetConfirmation();
  };

  useEffect(() => {
    // Fetch user details based on userId
    // Example: Fetch user details from an API
    const fetchUserDetails = async () => {
      try {
        const response = await UserService.getUser(userId, auth?.adminToken);
        // Check if the response is successful
        if (response.status === 200) {
          // Set users data from the response
          setUser(response.data.data);
          setFirstName(response.data.data.first_name || "");
          setLastName(response.data.data.last_name || "");
          setEmail(response.data.data.email || "");
          setStatus(response.data.data.status || false);
        } else {
          // Handle error response
          console.error("Failed to fetch users:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [userId, auth?.adminToken, status]);

  const handleToggleChangePasswordModal = () => {
    setIsChangePasswordModalOpen(!isChangePasswordModalOpen);
  };

  const handleChangePassword = async () => {
    try {
      const response = await UserService.changePassword(
        userId,
        newPassword,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        addToast("success", "Password has changed successfully!");
      } else {
        // Handle error response
        addToast("error", "Failed to change the password!");
        console.error("Failed to change the password:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to change the password!");
      console.error("Error changing password:", error);
    }

    // Close the modal after handling the password change
    handleToggleChangePasswordModal();
  };

  const handleUpdateUser = async () => {
    try {
      const response = await UserService.updateUserProfile(
        userId,
        email,
        firstName,
        lastName,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        addToast("success", "User Profile Updated successfully!");
      } else {
        // Handle error response
        addToast("error", "Failed to update user profile!");
        console.error("Failed to update user profile:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to update user profile!");
      console.error("Error while updating user:", error);
    }
  };

  const renderTasks = (tasks) => {
    return tasks.map((task) => (
      <div key={task.userTask} className="mt-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            checked={task.isCompleted}
            onChange={(e) =>
              handleTaskCompletion(task.userTask, e.target.checked)
            }
            className="mr-2"
          />
          {task.task.name}
        </label>
        <p>{task.task.description}</p>
      </div>
    ));
  };

  const renderCourses = () => {
    if (!user || !user.courses) {
      return null;
    }
    return user.courses.map((course) => (
      <div key={course.id} className="border p-4 my-4 bg-gray-100">
        <div className="flex items-center justify-between">
          <h3 className="text-black font-bold text-xl py-2 items-center">
            {course.name}
            <ToggleButton isChecked={course.status} onToggle={() => updateCourseStatus(!course.status, course.userCourse)} />
          </h3>
          <button
            className="bg-dark hover:bg-red-600 text-white py-2 px-4 rounded inline-flex items-center"
            onClick={openResetConfirmation}
          >
            <span>Reset Task Data</span>
          </button>
          <PopupModal
            isOpen={isResetConfirmationOpen}
            onClose={closeResetConfirmation}
            onConfirm={() => resetUserTasks(course.id)}
            message="Are you sure you want to Reset user task data?"
          />
        </div>
        {renderTasks(course.tasks)}
      </div>
    ));
  };

  const handleTaskCompletion = async (userTaskId, isChecked) => {
    try {
      const response = await UserService.unlockUserTask(
        userId,
        userTaskId,
        isChecked,
        auth?.adminToken
      );
      // Check if the response is successful
      if (response.status === 200) {
        // Set users data from the response
        setUser((prevUser) => ({
          ...prevUser,
          courses: prevUser.courses.map((course) => ({
            ...course,
            tasks: course.tasks.map((task) =>
              task.userTask === userTaskId
                ? { ...task, isCompleted: isChecked }
                : task
            ),
          })),
        }));
        addToast("success", "Task status has Updated.");
      } else {
        // Handle error response
        addToast("error", "Failed to update the task status!");
        console.error("Failed to update task status:", response.data.message);
      }
    } catch (error) {
      addToast("error", "Failed to update the task status!");
      console.error("Error updating task status:", error);
    }
  };

  return (
    <div className="mx-auto p-8 bg-white shadow-md mt-8">
      <h2 className="text-3xl font-bold text-dark">Manage User</h2>
      <div className="mt-4">
        <table className="w-full">
          <tbody>
            <tr>
              <td className="pr-8">
                {/* Left Column - User Name and Email */}
                {user && (
                  <>
                    <div>
                      <label
                        htmlFor="first_name"
                        className="text-black font-bold"
                      >
                        First Name:
                      </label>
                      <input
                        type="text"
                        id="first_name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="bg-white border border-gray-300 rounded-md p-2 mt-1 w-full"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="last_name"
                        className="text-black font-bold"
                      >
                        Last Name:
                      </label>
                      <input
                        type="text"
                        id="last_name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        className="bg-white border border-gray-300 rounded-md p-2 mt-1 w-full"
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="text-black font-bold">
                        Email:
                      </label>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="bg-white border border-gray-300 rounded-md p-2 mt-1 w-full"
                      />
                    </div>
                    <button
                      className="bg-dark text-white font-bold py-2 px-4 my-2 rounded"
                      onClick={() =>
                        handleUpdateUser({
                          first_name: firstName,
                          last_name: lastName,
                          email,
                        })
                      }
                    >
                      Update User
                    </button>
                  </>
                )}
              </td>

              <td className="float-right">
                <button
                  className="bg-dark text-white font-bold py-2 px-4 rounded my-2 w-full"
                  onClick={handleToggleChangePasswordModal}
                >
                  Change Password
                </button>
                <br />
                <button
                  className="bg-dark text-white font-bold py-2 px-4 rounded my-2 w-full"
                  onClick={toggleUserStatus}
                >
                  {status ? "Deactivate" : "Activate"}
                </button>
                <br />
                <button
                  className="bg-dark text-white font-bold py-2 px-4 rounded my-2 w-full"
                  onClick={killTokens}
                >
                  Clear API Tokens
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        {/* Change Password Modal */}
        {isChangePasswordModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center">
            <div className="bg-black bg-opacity-75 absolute inset-0"></div>
            <div className="bg-white p-6 rounded-lg z-10">
              <h2 className="text-2xl font-bold mb-4">Change Password</h2>
              <input
                type="password"
                placeholder="New Password"
                className="border p-2 mb-4 w-full"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <button
                className="bg-dark hover:bg-opacity-75 text-white font-bold py-2 px-4 rounded"
                onClick={handleChangePassword}
              >
                Change Password
              </button>
              <button
                className="bg-mid text-black font-bold py-2 px-4 mx-2 rounded"
                onClick={handleToggleChangePasswordModal}
              >
                Back
              </button>
            </div>
          </div>
        )}
      </div>
      {user && (
        <div className="mt-4">
          {/* Display user courses */}
          <div className="mt-6">
            <h3 className="text-xl font-bold text-dark">Courses</h3>
            {renderCourses()}
          </div>
        </div>
      )}
    </div>
  );
}