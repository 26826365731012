import React from 'react';
import Toast from './Toast';
import { useToast } from '../contexts/ToastContext';

const ToastContainer = () => {
  const { toasts, dismissToast } = useToast();

  return (
    <div className="fixed top-0 right-0 z-50 p-4">
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          type={toast.type}
          message={toast.message}
          dismiss
          onDismiss={() => dismissToast(toast.id)}
        />
      ))}
    </div>
  );
};

export default ToastContainer;
