import React, { useState, useEffect } from 'react';

const Toast = ({ type, message, dismiss, onDismiss }) => {
  const [visible, setVisible] = useState(true);

  const handleDismiss = () => {
    setVisible(false);
    // If onDismiss is provided, call it after a delay to allow the fade-out effect
    if (onDismiss) {
      setTimeout(() => {
        onDismiss();
      }, 300); // Adjust the delay as needed
    }
  };

  const getIconDarkColorClass = () => {
    switch (type) {
      case 'success':
        return 'text-green-200';
      case 'error':
        return 'text-red-200';
      default:
        return 'text-orange-200';
    }
  };

  const getIconDarkBackgroundClass = () => {
    switch (type) {
      case 'success':
        return 'bg-green-800';
      case 'error':
        return 'bg-red-800';
      default:
        return 'bg-orange-700';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'success':
        return (
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
        </svg>
        );
      case 'error':
        return (
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
        </svg>
        );
      default:
        return (
            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
        </svg>
        );
    }
  };

  useEffect(() => {
    // Automatically dismiss the toast after 5000ms (adjust as needed)
    const dismissTimer = setTimeout(() => {
      handleDismiss();
    }, 5000);

    // Clear the timer when the component unmounts or toast is manually dismissed
    return () => clearTimeout(dismissTimer);
  });

  return (
    <div
      id={`toast-${type}`}
      className={`flex items-center w-full max-w-xs p-4 mb-4 rounded-lg shadow text-gray-300 bg-gray-800 ${visible ? 'opacity-100' : 'opacity-0'}`}
      role="alert"
      style={{ transition: 'opacity 0.3s ease-in-out' }}
    >
      <div className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg ${getIconDarkColorClass()} ${getIconDarkBackgroundClass()}`}>
        {getIcon()}
      </div>
      <div className="ms-3 text-sm font-normal">{message}</div>
      {dismiss && (
        <button
          type="button"
          onClick={handleDismiss}
          className="ms-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 inline-flex items-center justify-center h-8 w-8 text-gray-500 hover:text-white bg-gray-800 hover:bg-gray-700"
          data-dismiss-target={`#toast-${type}`}
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
          </svg>
        </button>
      )}
    </div>
  );
};

export default Toast;
