import axiosServices from "../utils/axios";

const UserService = {
  getUsers: async (queryData, token) => {
    try {
      const response = await axiosServices.get(
        "/admin/users?start=" +
          queryData.start +
          "&limit=" +
          queryData.limit +
          "&search=" +
          queryData.search +
          "",
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  getUser: async (userId, token) => {
    try {
      const response = await axiosServices.get("/admin/user/" + userId, {
        headers: {
          "X-AUTH-TOKEN": token,
        },
      });

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  unlockUserTask: async (userId, taskId, isChecked, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId + "/unlock-task",
        { id: taskId, isCompleted: isChecked },
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  resetUserTasks: async (userId, courseId, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId + "/reset-tasks",
        { id: courseId },
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  updateUserStatus: async (userId, status, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId + "/status",
        { status: status },
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  killUserTokens: async (userId, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId + "/kill-tokens",
        {},
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  changePassword: async (userId, password, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId + "/password",
        { password: password },
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
  updateUserProfile: async (userId, email, firstName, LastName, token) => {
    try {
      const response = await axiosServices.post(
        "/admin/user/" + userId,
        { 
          email: email,
          first_name: firstName,
          last_name: LastName,
        },
        {
          headers: {
            "X-AUTH-TOKEN": token,
          },
        }
      );

      return response;
    } catch (error) {
      if (error.response) {
        return error.response;
      } else {
        throw error;
      }
    }
  },
};
export default UserService;
