import React, { useState, useEffect, useContext } from 'react';
import AuthContext from "../contexts/AuthContext";
import UserTable from '../components/users/UserTable';
import SearchBox from '../components/users/SearchBox';
import Pagination from '../components/Pagination';
import UserService from '../services/UserService';

export default function Users() {
  const { auth } = useContext(AuthContext);

const [users, setUsers] = useState([]);
const [totalUserCount, setUserCount] = useState(0);
const [query, setQuery] = useState("");
const [currentPage, setCurrentPage] = useState(1);
const usersPerPage = 10;

useEffect(() => {
  // Function to fetch users from the backend API
  const fetchUsers = async () => {
      try {
          // Make API call to fetch users
          const queryData = {
            'start':(currentPage-1)*10,
            'limit':10,
            'search':query,
          }
          const response = await UserService.getUsers(queryData, auth?.adminToken);
          
          // Check if the response is successful
          if (response.status === 200) {
              // Set users data from the response
              setUsers(response.data.data.users);
              setUserCount(response.data.data.totalCount);
          } else {
              // Handle error response
              console.error('Failed to fetch users:', response.data.message);
          }
      } catch (error) {
          // Handle error
          console.error('Error fetching users:', error.message);
      }
  };

  // Call the fetchUsers function
  fetchUsers();
}, [currentPage, query, auth?.adminToken]); // Execute only once on component mount

const handleSearch = (query) => {
    setCurrentPage(1); // Reset to first page on search
    setQuery(query);
};


// Change page
const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="container mx-auto px-4" style={{ width:'100vw' }}>
    <div>Users</div>
    <SearchBox onSearch={handleSearch} />
    <UserTable users={users} />
    <Pagination
                itemsPerPage={usersPerPage}
                totalItems={totalUserCount}
                // totalItems={20000}
                paginate={paginate}
                currentPage={currentPage}
            />
    </div>
  )
}
