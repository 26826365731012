import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../services/AuthService";
import AuthContext from "../contexts/AuthContext";
import PopupModal from "./PopupModal";

const LogoutModel = () => {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleLogout = async () => {
    try {
      const response = await AuthService.logout(auth?.adminToken);
      const shouldLogout =
        isModalOpen || window.confirm("Are you sure you want to logout?");

      if (!shouldLogout) {
        return;
      }

      if (response.status === 200) {
        setAuth({});
        navigate("/login");
      } else {
      }
    } catch (error) {}
  };

  const confirmLogout = () => {
    setIsModalOpen(false);
    handleLogout();
  };

  const cancelLogout = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <div>
      <button onClick={() => setIsModalOpen(true)}>Logout</button>

      <PopupModal
            isOpen={isModalOpen}
            onClose={cancelLogout}
            onConfirm={confirmLogout}
            message="Are you sure you want to logout?"
          />
    </div>
  );
};

export default LogoutModel;
