import { useContext, useState } from 'react';
import AuthService from '../../services/AuthService';
import AuthContext from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Login() {
    const [email, setEmail] = useState('');
    const [pwd, setPassword] = useState('');
    const [submitBtnDisable, disableSubmitBtn] = useState(0);
    const { setAuth } = useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const handleSubmit = async (e) => {
        disableSubmitBtn(1)
        e.preventDefault();
        const res = await AuthService.login(email,pwd);

        
        if(res?.status === 200){
            const adminEmail = res?.data?.email;
            const adminToken = res?.data?.token;
            setAuth({adminEmail, adminToken});
            navigate(from, { replace: true });
        }
        setEmail('');
        setPassword('');
        disableSubmitBtn(0);
    }

    return (
        <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-mid">
            <div className="w-full p-6 m-auto bg-white rounded-md shadow-xl ring-dark lg:max-w-xl">
                <img className='flex px-10 py-10' src='logo.png' alt='Brainlab' />
                <form className="mt-6" onSubmit={handleSubmit}>
                    <div className="mb-2">
                        <label
                            htmlFor="email"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            id="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                            required
                            className="block w-full px-4 py-2 mt-2 text-dark bg-white border rounded-md focus:border-dark focus:ring-dark focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mb-2">
                        <label
                            htmlFor="password"
                            className="block text-sm font-semibold text-gray-800"
                        >
                            Password
                        </label>
                        <input
                            id="password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            value={pwd}
                            required
                            className="block w-full px-4 py-2 mt-2 text-dark bg-white border rounded-md focus:border-dark focus:ring-dark focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                    </div>
                    <div className="mt-6">
                        <button
                        disabled={submitBtnDisable}
                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-dark rounded-md hover:bg-dark focus:outline-none focus:bg-dark disabled:bg-opacity-50">
                            Login
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}